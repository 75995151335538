import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center p-4">
            <h1 className="text-3xl font-bold text-center text-indigo-600 mb-6">Privacy Policy</h1>
            <div className="max-w-2xl w-full bg-white rounded-lg shadow-xl p-6">
                <p>Your privacy is important to us. This Privacy Policy outlines how we collect, use, and protect your information.</p>

                <h2 className="mt-4 text-2xl font-semibold mb-4">1. Data Collection</h2>
                <p>We collect information for the following purposes:
                    <ul className="list-disc pl-5">
                        <li>To improve the app's functionality and user experience</li>
                        <li>To provide marketing insights and analytics</li>
                        <li>For technical reasons, such as maintaining security and ensuring smooth app performance</li>
                    </ul>
                </p>

                <h2 className="mt-4 text-2xl font-semibold mb-4">2. Cookies</h2>
                <p>We use cookies for:
                    <ul className="list-disc pl-5">
                        <li>Technical reasons, to keep the app running smoothly</li>
                        <li>Improvement of the app's features</li>
                        <li>Marketing purposes, to offer relevant suggestions</li>
                    </ul>
                    You can manage your cookie preferences in your browser settings.</p>

                <h2 className="mt-4 text-2xl font-semibold mb-4">3. Use of OpenAI API</h2>
                <p>The AI tutor leverages the OpenAI API. Your interactions with the AI may be processed by OpenAI under their privacy guidelines. We do not have direct control over OpenAI’s data processing.</p>

                <h2 className="mt-4 text-2xl font-semibold mb-4">4. AWS Hosting</h2>
                <p>Our servers are hosted on AWS in Germany, ensuring compliance with European data protection laws.</p>

                <h2 className="mt-4 text-2xl font-semibold mb-4">5. Data Protection</h2>
                <p>We implement security measures to protect your data. However, no system is entirely secure, and we cannot guarantee absolute protection.</p>

                <h2 className="mt-4 text-2xl font-semibold mb-4">6. Your Rights</h2>
                <p>You have the right to access, modify, or delete your data at any time. Please contact us for any requests regarding your data via:
                    info(ät)kalbytes.de
                </p>

                <h2 className="mt-4 text-2xl font-semibold mb-4">7. Changes to the Privacy Policy</h2>
                <p>We may update this policy periodically. Continued use of the app signifies your acceptance of any changes.</p>

            </div>
        </div>
    );
};

export default PrivacyPolicy;