import React, { useState } from 'react';
import { CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import ConfirmSignup from './ConfirmSignup';
import { v4 as uuidv4 } from 'uuid';

interface SignupProps {
  userPool: CognitoUserPool;
  onSignupSuccess: () => void;
  onSwitchToLogin: () => void;
  onShowConfirmSignup: (email: string) => void;
}

function Signup({ userPool, onSignupSuccess, onSwitchToLogin, onShowConfirmSignup }: SignupProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(email.toLowerCase());
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setError('');
    setIsLoading(true);

    if (!validateEmail(email)) {
      setError('Please enter a valid email address');
      setIsLoading(false);
      return;
    }

    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
      setIsLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      setIsLoading(false);
      return;
    }

    if (!agreeToTerms) {
      setError('You must agree to the Terms and Conditions and Privacy Policy');
      setIsLoading(false);
      return;
    }

    const username = uuidv4();

    const attributeList = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email
      })
    ];

    userPool.signUp(username, password, attributeList, [], (err, result) => {
      if (err) {
        console.error('Error during signup:', err);
        setError(err.message || 'An error occurred during signup');
        setIsLoading(false);
        return;
      }
      console.log('Signup successful', result);
      setIsConfirming(true);
      localStorage.setItem('tempUsername', username);
      setIsLoading(false);
    });
  };

  const handleConfirmSuccess = () => {
    onSignupSuccess();
  };

  const handleResendCode = () => {
    console.log('Confirmation code resent');
  };

  if (isConfirming) {
    return (
      <ConfirmSignup
        userPool={userPool}
        email={email}
        onConfirmSuccess={handleConfirmSuccess}
        onResendCode={handleResendCode}
      />
    );
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4">Sign Up</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block mb-2">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="confirmPassword" className="block mb-2">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={agreeToTerms}
              onChange={(e) => setAgreeToTerms(e.target.checked)}
              className="mr-2"
            />
            <span className="text-sm">
              I agree to the{' '}
              <a href="/terms" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a href="/privacy" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline">
                Privacy Policy
              </a>
            </span>
          </label>
        </div>
        <button 
          type="submit" 
          className="w-full bg-green-500 text-white py-2 rounded mb-4 disabled:opacity-50"
          disabled={isLoading || !agreeToTerms}
        >
          {isLoading ? 'Signing up...' : 'Sign Up'}
        </button>
        <p className="text-center mb-4">
          Already have an account?{' '}
          <button type="button" onClick={onSwitchToLogin} className="text-blue-500">
            Log in
          </button>
        </p>
        <p className="text-center">
          Already signed up but haven't confirmed your email?{' '}
          <button
            type="button"
            onClick={() => onShowConfirmSignup(email)}
            className="text-blue-500"
          >
            Confirm Email
          </button>
        </p>
      </form>
    </div>
  );
}

export default Signup;