import React, { useState } from 'react';
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserSession } from 'amazon-cognito-identity-js';
import NewPasswordRequired from './NewPasswordRequired';

interface LoginProps {
  setUser: (user: CognitoUser) => void;
  onSwitchToSignup: () => void;
  userPool: CognitoUserPool;
  onShowConfirmSignup: (username: string) => void;
}

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
  ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID || '',
});

function Login({ setUser, onSwitchToSignup, userPool, onShowConfirmSignup }: LoginProps) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(email.toLowerCase());
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setError('');
    setIsLoading(true);

    if (!email) {
      setError('Please enter an email address');
      setIsLoading(false);
      return;
    }

    if (email && !validateEmail(email)) {
      setError('Please enter a valid email address');
      setIsLoading(false);
      return;
    }

    if (password.length < 8) {
      setError('Password must be at least 8 characters long');
      setIsLoading(false);
      return;
    }

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.authenticateUser(authenticationDetails, {
      onSuccess: (session: CognitoUserSession) => {
        console.log('Authentication successful', session);
        setUser(user);
        setIsLoading(false);
      },
      onFailure: (err) => {
        console.error('Authentication failed', err);
        setError(err.message || 'An error occurred during login');
        setIsLoading(false);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        console.log('New password required');
        setNewPasswordRequired(true);
        setCognitoUser(user);
        setIsLoading(false);
      }
    });
  };

  const handleNewPasswordSet = () => {
    setNewPasswordRequired(false);
    if (cognitoUser) {
      setUser(cognitoUser);
    }
  };

  if (newPasswordRequired && cognitoUser) {
    return <NewPasswordRequired user={cognitoUser} onPasswordSet={handleNewPasswordSet} />;
  }

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4">Login</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-3 py-2 border rounded"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block mb-2">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <button 
          type="submit" 
          className="w-full bg-blue-500 text-white py-2 rounded mb-4 disabled:opacity-50"
          disabled={isLoading}
        >
          {isLoading ? 'Logging in...' : 'Login'}
        </button>
        <p className="text-center mb-4">
          Don't have an account?{' '}
          <button type="button" onClick={onSwitchToSignup} className="text-blue-500">
            Sign up
          </button>
        </p>
        <p className="text-center">
          Need to confirm your email?{' '}
          <button
            type="button"
            onClick={() => onShowConfirmSignup(email)}
            className="text-blue-500"
          >
            Confirm Email
          </button>
        </p>
      </form>
    </div>
  );
}

export default Login;