import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import ScenarioSelector from './components/ScenarioSelector';
import ChatInterface from './components/ChatInterface';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';
import Login from './components/Login';
import Signup from './components/Signup';
import NewPasswordRequired from './components/NewPasswordRequired';
import ConfirmSignup from './components/ConfirmSignup';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import ShareModal from './components/ShareModal';
import WelcomeMessage from './components/WelcomeMessage';
import { FaBars, FaTimes } from 'react-icons/fa';


// Configure Cognito UserPool
const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
  ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID || '',
});

function App() {
  const [selectedScenario, setSelectedScenario] = useState<string | null>(null);
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [userEmail, setUserEmail] = useState<string>('');  // Replace username-related state with email
  const [isAuthChecked, setIsAuthChecked] = useState(false);
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [showConfirmSignup, setShowConfirmSignup] = useState(false);
  const [signupUsername, setSignupUsername] = useState('');  // Add this line
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);

  const handleBack = () => {
    setSelectedScenario(null);
  };

  const handleSignOut = () => {
    if (user) {
      user.signOut();
      setUser(null);
    }
  };

  const handleSwitchToSignup = () => {
    setShowSignup(true);
  };

  const handleSwitchToLogin = () => {
    setShowSignup(false);
  };

  const handleSignupSuccess = () => {
    setShowSignup(false);
    // Show a success message or automatically log the user in
    // For now, we'll just switch back to the login screen
    alert('Signup successful! Please log in with your new account.');
  };

  const handleShowConfirmSignup = (email: string) => {  // Change parameter to email
    setUserEmail(email);
    setShowConfirmSignup(true);
  };

  const handleConfirmSignupSuccess = () => {
    setShowConfirmSignup(false);
    setShowSignup(false);
    alert('Email confirmed successfully! Please log in with your new account.');
  };

  useEffect(() => {
    checkUser();
    
    // Check if the welcome message has been dismissed before
    const welcomeDismissed = localStorage.getItem('welcomeDismissed');
    if (welcomeDismissed === 'true') {
      setShowWelcomeMessage(false);
    }
  }, []);

  function checkUser() {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.getSession((err: any, session: any) => {
        if (err) {
          console.log('Error getting session:', err);
          setIsAuthChecked(true);
          return;
        }
        if (session.isValid()) {
          setUser(cognitoUser);
          cognitoUser.getUserAttributes((err, attributes) => {
            if (err) {
              console.log('Error getting user attributes:', err);
              setIsAuthChecked(true);
              return;
            }
            const emailAttribute = attributes?.find(attr => attr.Name === 'email');
            setUserEmail(emailAttribute?.Value || '');
            setIsAuthChecked(true);
          });
        } else {
          setIsAuthChecked(true);
        }
      });
    } else {
      setIsAuthChecked(true);
    }
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleShareModal = () => {
    setIsShareModalOpen(!isShareModalOpen);
  };

  const handleDismissWelcome = () => {
    setShowWelcomeMessage(false);
    localStorage.setItem('welcomeDismissed', 'true');
  };

  if (!isAuthChecked) {
    return <div>Loading...</div>;
  }
  const allowedRoutes = ['/terms', '/privacy'];
  if (!user && !allowedRoutes.includes(window.location.pathname)) {
    if (showConfirmSignup) {
      return (
        <ConfirmSignup
          userPool={userPool}
          email={userEmail}
          onConfirmSuccess={handleConfirmSignupSuccess}
          onResendCode={() => alert('Confirmation code resent. Please check your email.')}
        />
      );
    } else if (showSignup) {
      return (
        <Signup
          userPool={userPool}
          onSignupSuccess={handleSignupSuccess}
          onSwitchToLogin={handleSwitchToLogin}
          onShowConfirmSignup={handleShowConfirmSignup}
        />
      );
    } else {
      return (
        <Login
          setUser={setUser}
          onSwitchToSignup={handleSwitchToSignup}
          userPool={userPool}
          onShowConfirmSignup={handleShowConfirmSignup}
        />
      );
    }
  }

  if (newPasswordRequired && user) {
    return <NewPasswordRequired user={user} onPasswordSet={() => setNewPasswordRequired(false)} />;
  }

  return (
    <Router>
      <div className="min-h-screen bg-gray-100 flex flex-col items-center p-4 relative">
        <Link to="/" className="text-3xl font-bold text-center text-indigo-600 mb-6 hover:text-indigo-800 flex items-center">
        <img src="/logo.webp" alt="AI Language Tutor" className="rounded-full w-10 h-10 inline-block mr-2" />
        AI Language Tutor
        </Link>
        {showWelcomeMessage && <WelcomeMessage onDismiss={handleDismissWelcome} />}
        
        {user && (
          <div className="absolute top-4 right-4">
            <button onClick={toggleMenu} className="text-2xl text-indigo-600">
              {isMenuOpen ? <FaTimes /> : <FaBars />}
            </button>
            {isMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1">
                <Link to="/" className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-100">🏠 Home</Link>
                <Link to="/terms" className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-100">📜 Terms & Conditions</Link>
                <Link to="/privacy" className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-100">🔒 Privacy Policy</Link>
                <a href="mailto:info@kalbytes.de?subject=Support%20AI%20Language%20Tutor" className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-100">💬 Support &amp; Feedback</a>
                <button onClick={toggleShareModal} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-indigo-100">🔗 Share</button>
                <button 
                  onClick={handleSignOut} 
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-red-100 hover:text-red-500"
                >
                  🚪 Sign Out
                </button>
              </div>
            )}
          </div>
        )}

        <Routes>
          <Route path="/" element={
            <div className="max-w-2xl w-full bg-white rounded-lg shadow-xl p-6">
              {!selectedScenario ? (
                <ScenarioSelector onSelect={setSelectedScenario} />
              ) : (
                <ChatInterface scenario={selectedScenario} onBack={handleBack} />
              )}
            </div>
          } />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>

        <ShareModal isOpen={isShareModalOpen} onClose={toggleShareModal} />
      </div>
    </Router>
  );
}

export default App;
