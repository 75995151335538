import React, { useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';

interface NewPasswordRequiredProps {
  user: CognitoUser;
  onPasswordSet: () => void;
}

function NewPasswordRequired({ user, onPasswordSet }: NewPasswordRequiredProps) {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      setIsLoading(false);
      return;
    }

    user.completeNewPasswordChallenge(newPassword, {}, {
      onSuccess: () => {
        console.log('Password changed successfully');
        onPasswordSet();
        setIsLoading(false);
      },
      onFailure: (err) => {
        console.error('Failed to change password', err);
        setError(err.message || 'Failed to change password');
        setIsLoading(false);
      },
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4">Set New Password</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <div className="mb-4">
          <label htmlFor="newPassword" className="block mb-2">New Password</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="confirmPassword" className="block mb-2">Confirm Password</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <button 
          type="submit" 
          className="w-full bg-blue-500 text-white py-2 rounded disabled:opacity-50"
          disabled={isLoading}
        >
          {isLoading ? 'Setting Password...' : 'Set New Password'}
        </button>
      </form>
    </div>
  );
}

export default NewPasswordRequired;