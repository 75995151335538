import React from 'react';
import { FaWhatsapp, FaEnvelope, FaFacebookF } from 'react-icons/fa';

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ShareModal: React.FC<ShareModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const shareUrl = window.location.href;
  const shareText = "Check out this awesome AI Language Tutor!";

  const shareViaWhatsApp = () => {
    window.open(`https://wa.me/?text=${encodeURIComponent(shareText + ' ' + shareUrl)}`, '_blank');
  };

  const shareViaEmail = () => {
    window.location.href = `mailto:?subject=${encodeURIComponent(shareText)}&body=${encodeURIComponent(shareUrl)}`;
  };

  const shareViaFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, '_blank');
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-80">
        <h2 className="text-2xl font-bold mb-4">Share</h2>
        <div className="grid grid-cols-1 gap-4 mb-4">
          <button 
            onClick={shareViaWhatsApp} 
            className="flex items-center justify-center bg-green-500 text-white py-3 px-4 rounded-lg hover:bg-green-600 transition duration-300"
          >
            <FaWhatsapp size={24} className="mr-2" />
            <span>Share on WhatsApp</span>
          </button>
          <button 
            onClick={shareViaEmail} 
            className="flex items-center justify-center bg-blue-500 text-white py-3 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
          >
            <FaEnvelope size={24} className="mr-2" />
            <span>Share via Email</span>
          </button>
          <button 
            onClick={shareViaFacebook} 
            className="flex items-center justify-center bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
          >
            <FaFacebookF size={24} className="mr-2" />
            <span>Share on Facebook</span>
          </button>
        </div>
        <button
          onClick={onClose}
          className="w-full bg-indigo-600 text-white py-2 rounded hover:bg-indigo-700 transition duration-300"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ShareModal;