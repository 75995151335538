import React from 'react';

const TermsAndConditions: React.FC = () => {
    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center p-4">
            <h1 className="text-3xl font-bold text-center text-indigo-600 mb-6">Terms and Conditions</h1>
            <div className="max-w-2xl w-full bg-white rounded-lg shadow-xl p-6">
                <p>Welcome to our AI Language Learning App. By using our app, you agree to the following terms and conditions. Please read them carefully.</p>

                <h2 className="mt-4 text-2xl font-semibold mb-4">1. Use of the App</h2>
                <p>You may use the app to interact with an AI tutor for language learning purposes. Any misuse, including attempts to reverse-engineer the AI or violate the app’s integrity, will result in termination of your access.</p>

                <h2 className="mt-4 text-2xl font-semibold mb-4">2. Data Usage</h2>
                <p>We collect and store data necessary to improve our services, including usage patterns, technical information, and interaction data with the AI. For more details, please review our Privacy Policy.</p>

                <h2 className="mt-4 text-2xl font-semibold mb-4">3. OpenAI API Usage</h2>
                <p>Our AI tutor leverages the OpenAI API. By using this app, you agree to comply with OpenAI’s terms of service, in addition to these terms and conditions.</p>

                <h2 className="mt-4 text-2xl font-semibold mb-4">4. Intellectual Property</h2>
                <p>All content, including AI responses and instructional material, is the intellectual property of the app. Unauthorized reproduction or distribution is prohibited.</p>

                <h2 className="mt-4 text-2xl font-semibold mb-4">5. Limitation of Liability</h2>
                <p>We are not responsible for any inaccuracies or issues arising from AI-generated content. The AI’s responses are for educational purposes only and should not be considered professional advice.</p>

                <h2 className="mt-4 text-2xl font-semibold mb-4">6. Governing Law</h2>
                <p>These terms are governed by the laws of Germany, and any disputes will be resolved in the courts of Germany.</p>

                <h2 className="mt-4 text-2xl font-semibold mb-4">7. Changes to the Terms</h2>
                <p>We may update these terms from time to time. Continued use of the app indicates your acceptance of any updated terms.</p>

            </div>
        </div>
    );
};

export default TermsAndConditions;