import React, { useState, useRef } from 'react';

interface Props {
  onRecordingComplete: (blob: Blob) => void;
  disabled: boolean;
  isLoading: boolean;  // Add this prop
}

const AudioRecorder: React.FC<Props> = ({ onRecordingComplete, disabled, isLoading }) => {
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      const chunks: Blob[] = [];
      mediaRecorder.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/webm' });
        onRecordingComplete(blob);
      };

      mediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  return (
    <button
      onClick={isRecording ? stopRecording : startRecording}
      disabled={disabled}
      className={`px-4 py-2 rounded-full font-semibold text-white transition duration-150 ease-in-out ${
        isRecording
          ? 'bg-red-500 hover:bg-red-600'
          : disabled
          ? 'bg-gray-400 cursor-not-allowed'
          : 'bg-indigo-500 hover:bg-indigo-600'
      }`}
    >
      {isRecording ? 'Stop Recording' : isLoading ? 'Processing...' : 'Start Recording'}
    </button>
  );
};

export default AudioRecorder;