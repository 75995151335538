import React, { useState, useEffect } from 'react';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

interface ConfirmSignupProps {
  userPool: CognitoUserPool;
  email: string;  // Change this from username to email
  onConfirmSuccess: () => void;
  onResendCode: () => void;
}

function ConfirmSignup({ userPool, email, onConfirmSuccess, onResendCode }: ConfirmSignupProps) {
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState('');
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);

  useEffect(() => {
    const tempUsername = localStorage.getItem('tempUsername');
    if (tempUsername) {
      setUsername(tempUsername);
    }
  }, []);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    const cognitoUser = new CognitoUser({
      Username: username || email, // Use the stored username if available, otherwise fall back to email
      Pool: userPool
    });

    cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
      if (err) {
        console.error('Error confirming signup:', err);
        setError(err.message || 'An error occurred during confirmation');
        setIsLoading(false);
        return;
      }
      console.log('Signup confirmation successful', result);
      localStorage.removeItem('tempUsername'); // Clean up the temporary username
      onConfirmSuccess();
      setIsLoading(false);
    });
  };

  const handleResendCode = () => {
    setIsResending(true);

    const cognitoUser = new CognitoUser({
      Username: email,  // Use email instead of username
      Pool: userPool
    });

    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        console.error('Error resending confirmation code:', err);
        setError(err.message || 'An error occurred while resending the code');
        setIsResending(false);
        return;
      }
      console.log('Confirmation code resent successfully', result);
      onResendCode();
      setIsResending(false);
    });
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
      <form onSubmit={handleSubmit} className="bg-white p-8 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4">Confirm Your Account</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <p className="mb-4">Please enter the confirmation code sent to your email</p>
        <div className="mb-4">
          <label htmlFor="confirmationCode" className="block mb-2">Confirmation Code</label>
          <input
            type="text"
            id="confirmationCode"
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
            className="w-full px-3 py-2 border rounded"
            required
          />
        </div>
        <button 
          type="submit" 
          className="w-full bg-green-500 text-white py-2 rounded mb-4 disabled:opacity-50"
          disabled={isLoading}
        >
          {isLoading ? 'Confirming...' : 'Confirm Signup'}
        </button>
        <p className="text-center">
          Didn't receive the code?{' '}
          <button 
            type="button" 
            onClick={handleResendCode} 
            className="text-blue-500 disabled:opacity-50"
            disabled={isResending}
          >
            {isResending ? 'Resending...' : 'Resend Code'}
          </button>
        </p>
      </form>
    </div>
  );
}

export default ConfirmSignup;