import React from 'react';

const scenarios = [
  'Waiter: Ordering at a restaurant 🍔🍕🥤',
  'Shopkeeper: Grocery shopping 🛒🥬🥕',
  'Bus driver: Buying a bus ticket 🚌💸🎫',
  'Local person: Asking for directions 🗺️🧭🚏',
  'Hotel receptionist: Making a hotel reservation 🛏️🗓️🏨',
  'Fast food staff: Ordering food at a fast food restaurant 🍔🍕🥤',
  'Doctor: Asking for a prescription 🩺💊👨‍⚕️',
  'Barber: Asking for a haircut 💈💇‍♂️💇‍♀️',
  'Waiter: Asking a waiter for a menu 📜🍽️🍴',
  'Cashier: Asking a cashier for help 💰🛒🛍️',
  'Taxi driver: Asking a taxi driver for directions 🚕🗺️🧭',
  'Hotel receptionist: Asking a hotel receptionist for help 📞🏨💁‍♂️',

];

interface Props {
  onSelect: (scenario: string) => void;
}

const ScenarioSelector: React.FC<Props> = ({ onSelect }) => {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-1 text-gray-700">Select a scenario:</h2>
      <p className="text-sm text-gray-500 mb-4">Just select a scenario and get started!</p>
      <ul className="space-y-2">
        {scenarios.map((scenario) => (
          <li
            key={scenario}
            onClick={() => onSelect(scenario)}
            className="cursor-pointer bg-indigo-100 hover:bg-indigo-200 text-indigo-700 py-2 px-4 rounded transition duration-150 ease-in-out"
          >
            {scenario.split(': ')[1]}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ScenarioSelector;