import React from 'react';

interface Props {
  onDismiss: () => void;
}

const WelcomeMessage: React.FC<Props> = ({ onDismiss }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-indigo-600">Welcome to AI Language Tutor Beta!</h2>
        <p className="mb-4">
          Thank you for trying out the first beta version of our AI conversation app. We're excited to have you on board and would love to hear your feedback to help us improve.
        </p>
        <p className="mb-6">
          Please feel free to explore the app and let us know about your experience. Your input is invaluable to us!
        </p>
        <p className="mb-6">
            Use the <strong>button in the bottom right</strong> to open the feedback dialog.
        </p>
        <button
          onClick={onDismiss}
          className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition duration-150 ease-in-out"
        >
          Got it, let's start!
        </button>
      </div>
    </div>
  );
};

export default WelcomeMessage;