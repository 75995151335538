import React, { useState, useRef, useEffect } from 'react';
import AudioRecorder from './AudioRecorder';
import { HiChevronLeft } from "react-icons/hi";
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { ToastContainer, toast } from 'react-toastify';
import { FaInfoCircle } from 'react-icons/fa';

import 'react-toastify/dist/ReactToastify.css';

const apiUrl = process.env.REACT_APP_API_URL;
const greetings = ["Hello", "Hola", "Bonjour", "Ciao", "Hallo", "Olá", "Привет", "こんにちは", "你好", "สวัสดี"];

interface Message {
  role: 'user' | 'ai';
  content: string;
}

interface Props {
  scenario: string;
  onBack: () => void;  // Add this prop
}

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
  ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID || '',
});

const ChatInterface: React.FC<Props> = ({ scenario, onBack }) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [authToken, setAuthToken] = useState<string | null>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [placeholderIndex, setPlaceholderIndex] = React.useState(0);

  const playAudio = (url: string) => {
    if (audioRef.current) {
      audioRef.current.src = url;
      audioRef.current.load(); // Add this line to reload the audio source
      audioRef.current.play().catch(error => {
        console.error('Error playing audio:', error);
        console.log('Attempted to play audio from URL:', url);
        toast.error('Failed to play audio. Please check your audio settings.');
      });
    }
  };

  useEffect(() => {
    const getCurrentSession = async () => {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.getSession((err: any, session: any) => {
          if (err) {
            console.error('Error getting session:', err);
            return;
          }
          if (session.isValid()) {
            setAuthToken(session.getIdToken().getJwtToken());
          }
        });
      }
    };

    getCurrentSession();

    toast.info('Welcome to the conversation!', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }, []);

  const sendMessage = async (audioBlob: Blob) => {
    if (!authToken) {
      console.error('No auth token available');
      toast.error('Authentication error. Please try logging in again.');
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('audio', audioBlob);
    formData.append('scenario', scenario);
    formData.append('conversation_history', JSON.stringify(messages));

    try {
      const response = await fetch(`${apiUrl}/chat`, {
        method: 'POST',
        body: formData,
        headers: {
          'Authorization': `Bearer ${authToken}`
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'An error occurred while processing your request.');
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();

      if (reader) {
        let buffer = '';
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          buffer += decoder.decode(value, { stream: true });

          let boundary = buffer.indexOf('\n');
          while (boundary !== -1) {
            const chunk = buffer.slice(0, boundary);
            buffer = buffer.slice(boundary + 1);

            try {
              const data = JSON.parse(chunk);
              if (data.type === 'transcription') {
                setMessages((prev) => [...prev, { role: 'user', content: data.text }]);
              } else if (data.type === 'ai_response') {
                setMessages((prev) => [...prev, { role: 'ai', content: data.text }]);
              } else if (data.type === 'audio_url') {
                if (data.url) {
                  console.log('Received audio URL:', data.url);
                  playAudio(data.url);
                } else {
                  console.error('Received audio_url event with no URL');
                }
              }
            } catch (parseError) {
              console.error('Error parsing JSON:', parseError);
              toast.error('Error processing server response. Please try again.');
            }

            boundary = buffer.indexOf('\n');
          }
        }

        // Process any remaining data in the buffer
        if (buffer.trim()) {
          try {
            const data = JSON.parse(buffer);
            if (data.type === 'transcription') {
              setMessages((prev) => [...prev, { role: 'user', content: data.text }]);
            } else if (data.type === 'ai_response') {
              setMessages((prev) => [...prev, { role: 'ai', content: data.text }]);
            } else if (data.type === 'audio_url') {
              if (data.url) {
                playAudio(data.url);
              }
            }
          } catch (parseError) {
            console.error('Error parsing JSON:', parseError);
            toast.error('Error processing server response. Please try again.');
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error(error instanceof Error ? error.message : 'An unexpected error occurred.');
    } finally {
      setIsLoading(false);
    }
  };
  React.useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderIndex((prevIndex) => (prevIndex + 1) % greetings.length);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="space-y-4">
      <ToastContainer />
      <div className="flex justify-between gap-4 items-center mb-4">
        <button
          onClick={onBack}
          className="px-4 py-1 bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-full transition duration-150 ease-in-out"
        >
          <div className="flex items-center gap-2">
            <HiChevronLeft className="size-6" />
            Back
          </div>
        </button>
        <h2 className="text-xl font-semibold text-gray-700">Scenario: {scenario}</h2>
      </div>
      <div className="chat-messages space-y-4 max-h-96 overflow-y-auto p-4 bg-gray-50 rounded">
        {messages.length === 0 ? (
          <div className="text-center text-gray-500 italic flex flex-col items-center">
            <span>Start the conversation by saying: </span>
            <span>
            {greetings[placeholderIndex]}
            </span>
          </div>
        ) : (
          messages.map((message, index) => (
            <div
              key={index}
              className={`message p-3 rounded-lg ${message.role === 'user' ? 'bg-indigo-100 text-indigo-700' : 'bg-green-100 text-green-700'
                }`}
            >
              {message.content}
            </div>
          ))
        )}
        {isLoading && (
          <div className="flex justify-center items-center p-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-500"></div>
          </div>
        )}
      </div>
      <div className="flex flex-col items-center">
        <AudioRecorder
          onRecordingComplete={sendMessage}
          disabled={isLoading}
          isLoading={isLoading}
        />
        <div className="flex items-center mt-2">
          <span className="text-gray-400 cursor-help" title="You can speak in any language, and the AI will understand and respond accordingly.">
            <FaInfoCircle className="h-5 w-5" />
          </span>
          <p className="text-sm text-gray-600 ml-2">Speak naturally in your language of choice</p>
        </div>
      </div>
      <audio
        ref={audioRef}
        style={{ display: 'none' }}
        onError={(e) => console.error('Audio element error:', e)}
      />
    </div>
  );
};

export default ChatInterface;